import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'svg[robocode-close-icon]',
    standalone: true,
    imports: [],
    templateUrl: './close-icon.component.svg',
    styleUrl: './close-icon.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none"
    }
})
export class CloseIconComponent {

}
